<template>
	<Container
		group-name="leadkanbanlist"
		v-on:drop="onDrop(indexValue, rows, $event, statusData.value)"
		:get-child-payload="(e) => getChildPayload(rows, indexValue, e)"
		:drop-placeholder="dropPlaceholderOptions"
		class="overflow-list-item"
		:id="!rows.length ? 'blank-list' : ''"
	>
		<Draggable
			v-model="statusData.value"
			v-for="(data, index) in rows"
			:key="`child_${index}`"
			class=""
		>
			<div
				:class="`${statusData.status_color} lighten-5 list-items custom-line`"
				v-on:click="pushToRoute(data.id)"
			>
				<div class="ellipsis pr-6 mb-1">
					<span class="fw-600 pr-2">Title: </span>
					<span class="fw-500" v-if="data && data.billable_name">{{ data.billable_name }}</span>
					<em class="text-muted" v-else>no title</em>
				</div>
				<div class="ellipsis pr-6">
					<span class="fw-600 pr-2">User: </span>
					<span class="fw-500 primary--text" v-if="data && data.user_name">
						<v-chip small>
							<v-avatar left>
								<img v-if="data.profile_img" :src="data.profile_img" />
								<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
							</v-avatar>

							{{ data.user_name }}
						</v-chip>
						<!-- {{ data.user_name }} -->
					</span>
					<em class="text-muted" v-else>no user name</em>
				</div>
				<div class="ellipsis pr-6" v-if="data && data.category == 'customer'">
					<span class="fw-600 pr-2">Company: </span>
					<span class="fw-500 primary--text" v-if="data && data.customer_company_name">{{
						data.customer_company_name
					}}</span>
					<em class="text-muted" v-else>no company</em>
				</div>
				<div class="d-flex pr-6 my-1">
					<div>
						<span class="fw-600 pr-2">Amount: </span>
						<v-chip x-small label color="green" outlined v-if="data && data.amount">
							<span class="fw-500" v-if="data && data.amount">{{ formatMoney(data.amount) }}</span>
						</v-chip>
						<span v-else>
							<v-chip x-small label color="green" outlined>
								<span class="fw-500">$0.00</span>
							</v-chip>
						</span>
					</div>
				</div>
				<div class="ellipsis pr-6 mb-1">
					<span class="fw-600 pr-2">Category: </span>
					<v-chip
						text-color=""
						outlined
						label
						color="blue white--text"
						x-small
						v-if="data && data.expense_category"
					>
						<span class="fw-500" v-if="data && data.expense_category">{{ data.expense_category }}</span>
					</v-chip>
					<em class="text-muted" v-else>no expense category</em>
					<v-chip
						text-color=""
						outlined
						label
						color="orange white--text ml-1"
						x-small
						v-if="data && data.sub_category"
					>
						<span class="fw-500" v-if="data && data.sub_category">{{ data.sub_category }}</span>
					</v-chip>
				</div>
				<!--  <pre>{{data}}</pre> -->
				<!--   -->
				<!-- <div class="d-flex">
					<div class="ellipsis fw-500 mr-2 w-80" v-on:click="pushToRoute(data.id)">
						<v-tooltip top content-class="custom-top-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<span v-on="on" v-bind="attrs">
									<v-icon class="mdi-13px mr-1">mdi-pound</v-icon>
									<template v-if="data && data.barcode">{{ data.barcode }}</template>
									<template v-else> <em>no name</em> </template>
								</span>
							</template>
							<span>Lead #</span>
						</v-tooltip>
					</div>
					<div class="ellipsis fw-500 text-end w-20">
						<v-icon
							style="font-size: 18px"
							color="cyan"
							small
							v-on:click="update_lead(data.id)"
							v-if="getPermission('lead:update')"
							>mdi-pencil</v-icon
						>
					</div>
				</div>
				<div class="d-flex" v-on:click="pushToRoute(data.id)">
					<div class="ellipsis fw-500 mr-2 w-50">
						<v-tooltip top content-class="custom-top-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<span v-on="on" v-bind="attrs">
									<v-icon class="mdi-13px">fas fa-user</v-icon>
									<template v-if="data && data.customer_name"> {{ data.customer_name }}</template
									><template v-else> <em>no person incharge</em> </template>
								</span>
							</template>
							<span>Person Incharge</span>
						</v-tooltip>
					</div>
					<div class="ellipsis fw-500 text-end w-50">
						<template v-if="data.is_converted == 1">
							<v-tooltip top content-class="custom-top-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<span v-on="on" v-bind="attrs">
										<v-badge color="red" class="mr-4"> </v-badge>
									</span>
								</template>
								<span>Customer Created</span>
							</v-tooltip>
						</template>
					</div>
				</div>
				<div class="d-flex">
					<div class="ellipsis fw-500 mr-2 w-80">
						<v-tooltip top content-class="custom-top-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<span v-on="on" v-bind="attrs">
									<v-icon class="mdi-13px">fas fa-building</v-icon>
									<template v-if="data && data.company_name"> {{ data.company_name }}</template
									><template v-else> <em>no company name</em> </template>
								</span>
							</template>
							<span>Company Name</span>
						</v-tooltip>
					</div>
					<div class="fw-500 text-end w-20 cursor-pointer">
						<div>
							<v-checkbox
								hide-details
								v-model="data.pinned"
								class="ma-1"
								color="red"
								v-on:click.prevent.stop="leadPinned(data.id, statusData.value, 'pinned', data.pinned)"
							></v-checkbox>
						</div>
						<div>
							<v-checkbox
								hide-details
								v-model="data.pin"
								class=""
								color="blue"
								v-on:click.prevent.stop="leadPinned(data.id, statusData.value, 'pin', data.pin)"
							></v-checkbox>
						</div>
					</div>
				</div>
				<div class="d-flex" style="margin-bottom: -5px">
					<div class="ellipsis fw-500 mr-2 w-80" v-on:click="pushToRoute(data.id)">
						<v-tooltip top content-class="custom-top-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<span
									v-on="on"
									v-bind="attrs"
									v-on:click.stop="reminder_create = true"
									class="cursor-pointer"
								>
									<v-icon class="mdi-16px">mdi-reminder</v-icon>

									<template v-if="data && data.reminder && data.reminder.start_date">
										<template v-if="data && data.reminder.start_date && data.reminder.end_date">
											<span v-if="data && data.reminder && data.reminder.start_date">{{
												formatDateTime(data.reminder.start_date)
											}}</span>
										</template>
									</template>
									<em v-else class="text-muted"> no reminder {{ data.reminder }}</em>
								</span>
							</template>
							<span>Reminder</span>
						</v-tooltip>
					</div>
					<div class="ellipsis fw-500 text-end w-20" v-if="!data.reminder">
						<v-tooltip top content-class="">
							<template v-slot:activator="{ on, attrs }">
								<div v-on:click.stop="" class="" v-on="on" v-bind="attrs">
									<v-icon color="blue" class="text-end" v-on:click="openReminder(data)">mdi-alarm</v-icon>
								</div>
							</template>

							<template> Add Reminder </template>
						</v-tooltip>
					</div>
				</div>
				<div class="d-flex" style="margin-bottom: -5px" v-on:click="pushToRoute(data.id)">
					<div class="ellipsis fw-500 mr-2">
						<v-tooltip top content-class="custom-top-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<span v-on="on" v-bind="attrs">
									<i class="fa-solid fa-clock-rotate-left"></i>
									<v-icon class="mdi-16px">mdi-clock-outline</v-icon>
									<template v-if="data && data.added_at && data.contacted_today == 1">
										{{ data.added_at }}
									</template>

									<em v-else class="text-muted"> no last contacted </em>
								</span>
							</template>
							<span>Last Contacted</span>
						</v-tooltip>
					</div>
					<v-spacer></v-spacer>
					<div class="ellipsis fw-500 text-end mt-1">
						<v-tooltip top content-class="custom-top-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<span v-on="on" v-bind="attrs">
									<v-icon class="mdi-13px" style="font-size: 22px; color: black">mdi-currency-usd</v-icon>
									<template v-if="data && data.amount">
										<v-chip label color="green white--text" x-small v-if="data && data.amount">
											<span class="fw-500" v-if="data && data.amount">
												{{ formatMoney(data.amount) }}
											</span>
										</v-chip> </template
									><template v-else> <em>no amount</em> </template>
								</span>
							</template>
							<span>Amount</span>
						</v-tooltip>
					</div>
				</div>
				<div class="d-flex" v-on:click="pushToRoute(data.id)">
					<div class="ellipsis fw-500 text-end w-60" v-if="data.is_public == 1">
						<Chip text="Public" outlined color="pink" text-color="" small=""></Chip>
					</div>
				</div>
				<div class="d-flex" v-on:click="pushToRoute(data.id)">
					<div class="ellipsis fw-500 mr-2 w-40">
						<v-tooltip top content-class="custom-top-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									<v-icon class="mdi-25px">mdi-phone</v-icon>
									<template v-if="data && data.phone">
										<span
											class="primary--text cursor-pointer"
											v-on:click.prevent.stop="copyNumber(data.phone, 'phone')"
											>{{ data.phone }}</span
										>
									</template>
									<template v-else> <em>no phone</em> </template>
								</span>
							</template>
							<template>
								<span>Phone Number</span>
							</template>
						</v-tooltip>
					</div>
					<div class="ellipsis fw-500 text-end w-60">
						<v-tooltip top content-class="custom-top-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									<v-icon class="mdi-16px">mdi-email-outline</v-icon>
									<template v-if="data && data.email">
										<span
											class="primary--text cursor-pointer"
											v-on:click.prevent.stop="copyNumber(data.email)"
											>{{ data.email }}</span
										>
									</template>
									<template v-else> <em>no email</em> </template>
								</span>
							</template>
							<template> Email </template>
						</v-tooltip>
					</div>
				</div>
				<div class="d-flex">
					<div class="ellipsis fw-500 mr-2 w-80" v-on:click="pushToRoute(data.id)">
						<v-tooltip top content-class="custom-top-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									<v-icon class="mdi-16px">mdi-chat</v-icon>
									<template v-if="data && data.notes && data.notes[0] && data.notes[0].notes_remark">
										{{ data.notes[0].notes_remark }}
									</template>

									<template v-else> <em class="text-muted">no notes</em> </template>
								</span>
							</template>
							<template>
								<span>notes</span>
							</template>
						</v-tooltip>
					</div>
					<div class="ellipsis fw-500 text-end w-20">
						<v-icon
							style="font-size: 25px"
							:color="!data.notes[0].notes_remark ? '#c5bfbf' : 'green'"
							small
							v-on:click="openRemark(data)"
							>mdi-chat</v-icon
						>
					</div>
				</div>
				<div class="d-flex pr-6" v-on:click="pushToRoute(data.id)">
					<div class="tag fw-500">
						<div class="d-flex">
							<v-tooltip top content-class="custom-top-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<v-chip class="mb-1 mr-1" label color="blue" x-small v-bind="attrs" v-on="on">
										<span v-bind="attrs" v-on="on" class="blue white--text">
											<template v-if="data && data.source">
												{{ data.source.replace("-", " ") }}
											</template>
										</span>
									</v-chip>
								</template>
								<span>
									<template> Source </template>
								</span>
							</v-tooltip>

							<v-tooltip top content-class="custom-top-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<v-chip class="mb-1 mr-1" label color="green" x-small v-bind="attrs" v-on="on">
										<span v-bind="attrs" v-on="on" class="green white--text">
											<template v-if="data && data.product_type">
												{{ data.product_type.replace("-", " ") }}
											</template>
										</span>
									</v-chip>
								</template>
								<span>
									<template> Product Type </template>
								</span>
							</v-tooltip>

							<v-tooltip top content-class="custom-top-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<v-chip
										v-if="data && data.opportunity == 'high-chance'"
										class="mb-1 mr-1"
										label
										color="orange"
										x-small
										v-bind="attrs"
										v-on="on"
									>
										<span v-bind="attrs" v-on="on" class="orange white--text">
											<template v-if="data && data.opportunity">
												{{ data.opportunity.replace("-", " ") }}
											</template>
										</span>
									</v-chip>
									<v-chip
										v-if="data && data.opportunity == 'low-chance'"
										class="mb-1 mr-1"
										label
										color="cyan"
										x-small
										v-bind="attrs"
										v-on="on"
									>
										<span v-bind="attrs" v-on="on" class="cyan white--text">
											<template v-if="data && data.opportunity">
												{{ data.opportunity.replace("-", " ") }}
											</template>
										</span>
									</v-chip>
									<v-chip
										v-if="data && data.opportunity == '50-50'"
										class="mb-1 mr-1"
										label
										color="green"
										x-small
										v-bind="attrs"
										v-on="on"
									>
										<span v-bind="attrs" v-on="on" class="green white--text">
											<template v-if="data && data.opportunity">
												{{ data.opportunity.replace("-", " ") }}
											</template>
										</span>
									</v-chip>
									<v-chip
										v-if="data && data.opportunity == 'none'"
										class="mb-1 mr-1"
										label
										color="blue"
										x-small
										v-bind="attrs"
										v-on="on"
									>
										<span v-bind="attrs" v-on="on" class="blue white--text">
											<template v-if="data && data.opportunity">
												{{ data.opportunity.replace("-", " ") }}
											</template>
										</span>
									</v-chip>
								</template>
								<span> <template> Opportunity </template></span>
							</v-tooltip>
						</div>
					</div>
				</div> -->
			</div>
		</Draggable>
		<template v-if="total_page > current_page">
			<v-btn
				depressed
				tile
				block
				:color="`${statusData ? statusData.status_color : ''}`"
				class="text-white float-right mb-4 d-block"
				v-on:click="getPaginationData(current_page, statusData.value)"
				:disabled="pageLoading"
				:loading="pageLoading"
			>
				<v-icon left> mdi-eye </v-icon>
				Load More
			</v-btn>
		</template>
	</Container>
</template>
<style>
.theme--dark.v-overlay.v-overlay--active {
	z-index: 99 !important;
}
.kanban-view-list .kanban-innerlist .v-input--selection-controls.v-input {
	position: absolute;
	right: -8px;
	top: 50px;
}
.v-input--selection-controls {
	margin-top: 4px;
	padding-top: 4px;
	margin-right: 30px;
}
</style>
<script>
import { Container, Draggable } from "vue-smooth-dnd";
import { QUERY, PATCH } from "@/core/services/store/request.module";
import { cloneDeep, concat, find } from "lodash";
//import Chip from "@/view/components/Chip";
import { SET_MESSAGE } from "@/core/services/store/common.module";

export default {
	name: "LeadKanbanList",
	data() {
		return {
			rows: [],
			total_page: 0,
			current_page: 1,
			statusData: {},
			dropPlaceholderOptions: {
				className: "lead-drop-preview",
				animationDuration: "50",
				showOnTop: true,
			},
			reminder_create: false,
			pageLoading: false,
			icon: "mdi-plus",
		};
	},
	props: {
		indexValue: {
			type: Number,
			default() {
				return 0;
			},
		},
		leadDetails: {
			type: Object,
			default() {
				return {};
			},
		},
		paginationEndPoint: {
			type: String,
			default() {
				return null;
			},
		},
	},
	watch: {
		leadDetails: {
			deep: true,
			handler(param) {
				const _this = this;
				_this.$nextTick(() => {
					_this.init(param);
				});
			},
		},
	},

	methods: {
		init(param) {
			//console.log(param);
			this.rows = param.list;
			//console.log(this.rows );
			this.statusData = param.status;
			this.total_page = param.total_page;
			this.current_page = param.current_page;
		},
		getPaginationData(current_page, status) {
			this.pageLoading = true;
			const { query } = this.$route;
			this.$store
				.dispatch(QUERY, {
					url: this.paginationEndPoint,
					data: {
						...query,
						status: status,
						current_page: current_page + 1,
					},
				})
				.then((data) => {
					let updatedData = find(data, (row) => row.status.value == status);
					if (updatedData) {
						let newListData = updatedData.list;
						let preListData = cloneDeep(this.rows);
						this.rows = concat(preListData, newListData);
						this.total_page = updatedData.total_page;
						this.current_page = updatedData.current_page;
					}
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		leadPinned(id, status, type, data) {
			this.$store
				.dispatch(PATCH, {
					url: `pin-lead/${id}`,
					data: {
						value: data,
						type: type,
						status: status,
					},
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Lead Pinned successfully." },
					]);
					this.getClaimListing();
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		copyNumber(data, type) {
			if (type == "phone") {
				data = data.split("-").pop();
			}
			navigator.clipboard.writeText(data);
			this.$store.commit(SET_MESSAGE, [
				{ model: true, message: `<b>${data}</b> - successfully coppied.` },
			]);
		},

		update_lead(id) {
			this.$emit("update:lead", id);
		},

		pushToRoute(id) {
			this.$router.push({
				name: "claims-details",
				params: { id },
				query: { t: new Date().getTime(), viewType: true },
			});
		},

		openReminder(data) {
			this.$emit("open-reminder", data);
		},

		openRemark(data) {
			this.$emit("open-remark", data);
		},

		onDrop(index, collection, dropResult, dropStatus) {
			let values = { index, collection, dropResult, dropStatus };
			this.$emit("drop", values);
		},

		getChildPayload(row, index, event) {
			let values = { row, index, event };
			this.$emit("child-payload", values);
		},
	},
	components: {
		Container,
		Draggable,
		//Chip,
	},
	mounted() {
		this.$nextTick(() => {
			this.init(this.leadDetails);
		});
		//console.log(this.leadDetails);
	},
};
</script>
